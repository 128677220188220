<template>
  <div class="homePage mobile-padding-10">
    <div class="main">
      <div class="left">
        <div class="top padding-common">
          <div class="infos">
            <div class="main-info">
              <div class="logo">
                <img :src="company.avatar.url" />
              </div>
              <div class="info">
                <div class="name">{{ company.company_name }}</div>
                <div class="type">
                  {{ company.business_type }} ·
                  {{ company.company_state_address }}
                  {{ company.company_country_info.name }}
                </div>
              </div>
            </div>
            <!--            <div class="search">-->
            <!--              <div class="input"><input type="text" :placeholder="'Search in ' + company.company_name" v-model="searchProductValue"/></div>-->
            <!--              <div class="btn search-button" @click="searchProduct">-->
            <!--                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                  <path d="M15.677 16.6076L9.96198 10.8916C7.41965 12.6991 3.91642 12.257 1.90285 9.87456C-0.110711 7.49214 0.0371394 3.96422 2.24298 1.75863C4.44824 -0.447923 7.97651 -0.596414 10.3594 1.41705C12.7422 3.4305 13.1846 6.93408 11.377 9.47663L17.092 15.1926L15.678 16.6066L15.677 16.6076ZM6.48498 2.00062C4.58868 2.00019 2.95267 3.33131 2.56745 5.18807C2.18224 7.04482 3.15369 8.9169 4.89366 9.67087C6.63362 10.4248 8.66388 9.85346 9.75522 8.30268C10.8466 6.7519 10.699 4.64795 9.40198 3.26463L10.007 3.86463L9.32498 3.18463L9.31298 3.17263C8.56477 2.41981 7.54637 1.99776 6.48498 2.00062Z" fill="white"/>-->
            <!--                </svg>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="other">
            <div class="top-border"></div>
            <div class="nav">
              <div
                :class="[
                  'item',
                  'profile',
                  { active: companyDetailType === 'profile' }
                ]"
                @click="changeTitle('profile')"
              >
                <div class="name">Company profile</div>
                <div
                  :class="[
                    'item-line',
                    { 'active-line': companyDetailType === 'profile' }
                  ]"
                ></div>
              </div>
              <div
                :class="[
                  'item',
                  'contact',
                  { active: companyDetailType === 'contact' }
                ]"
                @click="changeTitle('contact')"
              >
                <div class="name">Contact</div>
                <div
                  :class="[
                    'item-line',
                    { 'active-line': companyDetailType === 'contact' }
                  ]"
                ></div>
              </div>
              <div
                :class="[
                  'item',
                  'product',
                  { active: companyDetailType === 'product' }
                ]"
                @click="changeTitle('product')"
              >
                <div class="name">Products</div>
                <div
                  :class="[
                    'item-line',
                    { 'active-line': companyDetailType === 'product' }
                  ]"
                ></div>
              </div>
              <div
                :class="[
                  'item',
                  'document',
                  { active: companyDetailType === 'document' }
                ]"
                @click="changeTitle('document')"
                v-if="company.level != 'Basic'"
              >
                <div class="name">Documents</div>
                <div
                  :class="[
                    'item-line',
                    { 'active-line': companyDetailType === 'document' }
                  ]"
                ></div>
              </div>
              <div
                :class="[
                  'item',
                  'document',
                  'basic-document',
                  { active: companyDetailType === 'document' }
                ]"
                v-if="company.level === 'Basic'"
              >
                <div class="name">Documents</div>
              </div>
              <div
                :class="[
                  'item',
                  'factory',
                  { active: companyDetailType === 'factory' }
                ]"
                @click="changeTitle('factory')"
                v-if="company.level != 'Basic'"
              >
                <div class="name">Factories</div>
                <div
                  :class="[
                    'item-line',
                    { 'active-line': companyDetailType === 'factory' }
                  ]"
                ></div>
              </div>
              <div
                :class="[
                  'item',
                  'factory',
                  'basic-factory',
                  { active: companyDetailType === 'factory' }
                ]"
                v-if="company.level === 'Basic'"
              >
                <div class="name">Factories</div>
              </div>
              <div
                :class="[
                  'item',
                  'member',
                  { active: companyDetailType === 'member' }
                ]"
                @click="changeTitle('member')"
              >
                <div class="name">Members</div>
                <div
                  :class="[
                    'item-line',
                    { 'active-line': companyDetailType === 'member' }
                  ]"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="companyDetailType === 'profile'">
          <div class="overview padding-common">
            <div class="title card-title">Overview</div>
            <div class="desc">
              <div
                style="word-break: break-all;"
                v-html="company.information_panel"
              />
            </div>
            <div class="detail">
              <div class="item c-item">
                <div class="label">Type</div>
                <div class="value">{{ company.business_type }}</div>
              </div>
              <div class="item c-item">
                <div class="label label2">No.of Employees</div>
                <div class="value ">{{ company.company_size }}</div>
              </div>
              <div class="item c-item">
                <div class="label">View Count</div>
                <div class="value">{{ company.view_history_count }}</div>
              </div>
              <div class="item c-item">
                <div class="label label2">Year Established</div>
                <div class="value">{{ company.established_year }}</div>
              </div>
              <div class="c-item">
                <div class="label">Location</div>
                <div class="value">{{ company.company_country_info.name }}</div>
              </div>
              <div class="item c-item">
                <div class="label label2">Annual Sales Revenue</div>
                <div class="value">{{ company.company_annual_revenue }}</div>
              </div>
            </div>
          </div>
          <div
            class="hightlight padding-common"
            v-if="highLight.length > 0 && company.level === 'Diamond'"
          >
            <div class="title card-title">Highlight</div>
            <div class="content">
              <div
                class="high-light-item"
                v-for="(item, key) in highLight"
                :key="key"
              >
                · {{ item.name }}
              </div>
            </div>
          </div>
          <div class="photo padding-common" v-if="company.level === 'Diamond'">
            <div class="title card-title">
              <div class="name">Media Gallery</div>
              <div class="icon">
                <div
                  class="item search-button prev2"
                  style="margin-right: 20px;"
                  @click="prev2"
                >
                  <svg
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="19" cy="19" r="19" fill="#F99D20" />
                    <path
                      d="M14.0507 19.0002L22.064 27.0135L23.9493 25.1282L17.816 18.9948L23.9493 12.8615L22.064 10.9868L14.0507 19.0002Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div class="item search-button next2" @click="next2">
                  <svg
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="19" cy="19" r="19" fill="#F99D20" />
                    <path
                      d="M23.9493 19.0002L15.936 27.0135L14.0507 25.1282L20.184 18.9948L14.0507 12.8615L15.936 10.9868L23.9493 19.0002Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="swiper-container swiper2">
                <div class="swiper-wrapper">
                  <div v-if="video" class="video-content imgshows swiper-slide">
                    <video-player
                      :options="{
                        poster,
                        controls: true,
                        sources: [
                          {
                            src: video,
                            type: 'video/mp4'
                          }
                        ]
                      }"
                    />
                  </div>
                  <div
                    class="imgshows swiper-slide"
                    v-for="(img, index) in company.information_panel_image"
                    :key="index"
                  >
                    <img alt="" :src="img.url" />
                  </div>
                </div>
              </div>
              <!--            <div class="swiper-button-prev prev2" @click="prev2"></div>-->
              <!--            <div class="swiper-button-next next2" @click="next2"></div>-->
            </div>
          </div>
        </template>
        <product
          :id="id"
          v-if="companyDetailType === 'product' && company.level != 'Basic'"
          :search="searchProductValue"
        />
        <basicProduct
          :id="id"
          v-if="companyDetailType === 'product' && company.level === 'Basic'"
          :search="searchProductValue"
        />
        <template v-if="role">
          <document :id="id" v-if="companyDetailType === 'document'" />
          <factory :id="id" v-if="companyDetailType === 'factory'" />
          <member :id="id" v-if="companyDetailType === 'member'" :role="role" />
        </template>
        <template v-else>
          <div
            class="not-login"
            v-if="
              ['document', 'factory', 'member'].indexOf(companyDetailType) > -1
            "
          >
            <div class="pic">
              <img src="@/assets/not-login-info.png" />
            </div>
            <router-link to="/user-action/login">
              <div class="info">
                <div class="warning">
                  <img src="@/assets/warning.png" />
                </div>
                Login to see more features
              </div>
            </router-link>
          </div>
        </template>
      </div>
      <!--      <div class="right padding-common">-->
      <!--        <div class="title card-title">Contact</div>-->
      <!--        <div class="right-main">-->
      <!--          <div class="logo">-->
      <!--            <img :src="company.first_user.avatar.url" alt=""/>-->
      <!--          </div>-->
      <!--          <div class="info">-->
      <!--            <div class="name">{{company.first_user.last_name}}</div>-->
      <!--            <div class="job">{{company.first_user.job_title}}</div>-->
      <!--              <div class="contact search-button" @click="message(company.id,company.first_user.id)">Message</div>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--        <div class="border"></div>-->
      <!--        <div class="quick">-->
      <!--          <div class="title">Quick Actions</div>-->
      <!--          <div class="actions">-->
      <!--            <div class="item search-button" v-if="!is_exchanged_business_card">-->
      <!--              <div class="icon">-->
      <!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--                  <path d="M20 19.9999H4C2.93052 20.0319 2.03642 19.1933 2 18.1239V5.87494C2.03641 4.80575 2.93068 3.96737 4 3.99994H20C21.0693 3.96737 21.9636 4.80575 22 5.87494V18.1249C21.963 19.1939 21.0691 20.032 20 19.9999ZM4 5.99994V17.9889L20 17.9999V6.01094L4 5.99994ZM13.43 15.9999H6C6.07353 15.172 6.46534 14.4049 7.093 13.8599C7.79183 13.1667 8.73081 12.7691 9.715 12.7499C10.6992 12.7691 11.6382 13.1667 12.337 13.8599C12.9645 14.405 13.3563 15.1721 13.43 15.9999ZM18 14.9999H15V12.9999H18V14.9999ZM9.715 11.9999C9.17907 12.0186 8.65947 11.8138 8.28029 11.4347C7.9011 11.0555 7.69638 10.5359 7.715 9.99994C7.69668 9.4641 7.9015 8.94468 8.28062 8.56556C8.65974 8.18644 9.17916 7.98162 9.715 7.99994C10.2508 7.98162 10.7703 8.18644 11.1494 8.56556C11.5285 8.94468 11.7333 9.4641 11.715 9.99994C11.7336 10.5359 11.5289 11.0555 11.1497 11.4347C10.7705 11.8138 10.2509 12.0186 9.715 11.9999ZM18 10.9999H14V8.99994H18V10.9999Z" fill="#828282"/>-->
      <!--                </svg>-->

      <!--              </div>-->
      <!--              <div class="word" @click="exchangeBusinessCard">Exchange Business Card</div>-->
      <!--            </div>-->
      <!--            <div class="item search-button">-->
      <!--              <div class="icon">-->
      <!--                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--                  <path d="M14 0C15.1046 0 16 0.895431 16 2L2 2L2 13.1765C0.895432 13.1765 0 12.281 0 11.1765V2C0 0.895428 0.895431 0 2 0H14Z" fill="#828282"/>-->
      <!--                  <path d="M12 20L9.33333 17.1765H6C4.89543 17.1765 4 16.281 4 15.1765V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V15.1765C20 16.281 19.1046 17.1765 18 17.1765H14.6667L12 20ZM13.8046 15.1765L18 15.1765V6L6 6V15.1765H10.1954L12 17.0872L13.8046 15.1765Z" fill="#828282"/>-->
      <!--                </svg>-->

      <!--              </div>-->
      <!--              <div class="word" @click="showQues">Ask a Question</div>-->
      <!--            </div>-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--    <Ads adsnum="0"></Ads>-->
    <Modal class="seller-c" title="Info" @on-cancel="cancel" v-model="showInfo">
      <div class="content">
        Seller does not have the permission.
      </div>
    </Modal>
    <Modal
      class="askquestion"
      v-model="showExchange"
      title="Exchange Required"
      footer-hide
      @on-cancel="cancel"
    >
      <div class="content">
        You must exchange business cards in order to respond to an inquiry or
        start a chatroom with another company's member.
      </div>
      <div
        class="btn"
        style="margin-top:15px;text-align: right;color: #ff6600;"
      >
        <Button
          @click="cancel"
          style="width:100px;border: 1px solid #ff6600;color:#ff6600;margin-right:10px;"
          >Cancel</Button
        >
        <Button
          v-if="btnloading"
          loading
          style="width:235px;background: #ff6600;border: 1px solid #ff6600;color:#fff"
          >Submitting...</Button
        >
        <Button
          v-else
          @click="exchangeBusinessCard('ques')"
          style="width:235px;background: #ff6600;border: 1px solid #ff6600;color:#fff"
          >Exchange Business Cards</Button
        >
      </div>
    </Modal>

    <Askquestion ref="askQ"></Askquestion>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
// import Ads from "@/components/ads";
import api from "@/api";
import VideoPlayer from "@/components/VideoPlayer";
import document from "./company-info-documents-mobile";
import factory from "./company-info-factory-mobile";
import member from "./company-info-member-mobile";
import product from "./company-info-product-mobile";
import basicProduct from "./company-info-product-basic-pc";
import { mapState } from "vuex";
import Askquestion from "@/components/askquestion";
import poster from "@/assets/poster2.png";

const {
  _queryCompanyCompany,
  // _queryCompanyProduct,
  _exchangeBusinessCard
} = api;
export default {
  computed: {
    ...mapState("user", [
      "role",
      "first_name",
      "last_name",
      "avatar",
      "user_id"
    ])
  },
  // props: ["company"],

  components: {
    // Ads,
    VideoPlayer,
    document,
    factory,
    member,
    product,
    Askquestion,
    basicProduct
  },
  data() {
    return {
      poster,
      companyDetailType: "profile",
      id: 0,
      loading: false,
      company: {},
      companyProduct: [],
      video: "",
      swiper1: "",
      swiper2: "",
      swiper3: "",
      searchProductValue: "",
      showExchange: false,
      btnloading: false,
      exchangeBusinessCardLoading: false,
      is_rated: false,
      is_exchanged_business_card: false,
      highLight: [],
      showInfo: false
    };
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;

    this.loading = true;

    _queryCompanyCompany({ id })
      .then(response => {
        const { data } = response;
        this.role = data.role;
        this.company = data;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
        const data = this.company;
        this.is_exchanged_business_card = data.is_exchanged_business_card;
        this.level = data.level;
        this.level_color = data.level_color;
        this.video = data.video?.url;
        this.highLight = data.highlights;
        this.initSwiper2();
      });
  },
  watch: {
    company() {}
  },
  mounted() {
    this.$nextTick(() => {});
    // const data = this.company;
    // _queryCompanyProduct({ 'id':this.id,limit:30 })
    //   .then(response => {
    //     const { data } = response;
    //     this.companyProduct = data.data;
    //
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     this.loading = false;
    //     this.initSwiper1()
    //     this.initSwiper2()
    //     this.initSwiper3()
    //   });
  },
  methods: {
    message(companyId, userId) {
      if ("exhibitor" === this.role) {
        this.showInfo = true;
        return;
      }
      if (this.is_exchanged_business_card) {
        this.$router.push("/chat?company=" + companyId + "&user=" + userId);
      } else {
        this.showExchange = true;
      }
    },
    viewAllProduct(id) {
      this.$router.push(
        "/company/" +
          id +
          "/products?utm_source=conference.connexima.com&utm_medium=referrals&utm_campaign=connexima_hvb"
      );
    },
    viewProduct(item) {
      if (item.redirect_url) {
        window.open(item.redirect_url);
      } else {
        this.$router.push(`/products/` + item.id);
      }
    },
    cancel() {
      this.showExchange = false;
    },

    showQues() {
      if ("exhibitor" === this.role) {
        this.showInfo = true;
        return;
      }
      if (this.is_exchanged_business_card) {
        this.$refs.askQ.showAskques = true;
      } else {
        this.showExchange = true;
      }
    },

    exchangeBusinessCard(type) {
      if (!this.role) {
        this.$router.push("/user-action/login");
        return;
      }
      if ("exhibitor" === this.role) {
        this.showInfo = true;
        return;
      }
      this.btnloading = true;
      this.exchangeBusinessCardLoading = true;
      _exchangeBusinessCard({
        id: this.id
      })
        .then(() => {
          // console.log(response);
          this.is_exchanged_business_card = true;
          // this.$Notice.open({
          //   title: `You have exchanged business cards with ${this.company_name}.`
          // });

          if (type === "ques") {
            this.showExchange = false;
            this.showInfo();
            this.$refs.askQ.showAskques = true;
          }
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.btnloading = false;
          this.exchangeBusinessCardLoading = false;
        });
    },
    searchProduct() {
      this.companyDetailType = "product";
    },
    initSwiper1() {
      this.swiper1 = new Swiper(".swiper1", {
        autoplay: {
          delay: 3000
        },
        loop: true,
        direction: "horizontal",
        slidesPerView: 4,
        spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".next1",
          prevEl: ".prev1"
        }
      });
    },
    initSwiper3() {
      this.swiper3 = new Swiper(".swiper3", {
        autoplay: {
          delay: 3000
        },
        loop: true,
        direction: "horizontal",
        slidesPerView: 6,
        spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".next3",
          prevEl: ".prev3"
        }
      });
    },
    initSwiper2() {
      this.swiper2 = new Swiper(".swiper2", {
        autoplay: {
          delay: 3000
        },
        loop: true,
        direction: "horizontal",
        slidesPerView: 3,
        spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".next2",
          prevEl: ".prev2"
        }
      });
    },
    navJump(path) {
      if ("home" == path) {
        this.$router.push("/index");
      }
      if ("products" == path) {
        this.$router.push(
          "/company/" +
            this.id +
            "/products?utm_source=conference.connexima.com&utm_medium=referrals&utm_campaign=connexima_hvb"
        );
      }
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        // autoplay: {
        //   delay: 3000
        // },
        loop: true,
        direction: "horizontal",
        slidesPerView: 4,
        spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    },
    prev1() {
      this.swiper1.slidePrev();
    },
    next1() {
      this.swiper1.slideNext();
    },
    prev() {},
    next() {},
    prev3() {
      this.swiper3.slidePrev();
    },
    next3() {
      this.swiper3.slideNext();
    },
    prev2() {
      this.swiper2.slidePrev();
    },
    next2() {
      this.swiper2.slideNext();
    },
    changeTitle(title) {
      this.companyDetailType = title;
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
.homePage {
  background: #f2f2f2;
  .main {
    /*display: flex;*/
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 115px;
    width: 100%;
    .left {
      .infos {
        display: flex;
        justify-content: space-between;
        .main-info {
          /*display: flex;*/
          .info {
            position: relative;
            bottom: 40px;
          }
        }
        .search {
          display: flex;
          margin-top: 19px;
          .input {
            input {
              border-radius: 8px;
              width: 230px;
              height: 38px;
              background: #f2f2f2;
              border: none;
              outline: none;
              font-weight: 500;
              font-size: 14px;
              line-height: 48px;

              padding-left: 10px;
            }
          }
          .btn {
            width: 50px;
            height: 38px;
            background: #f57f45;
            border-radius: 0px 8px 8px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .logo {
        background: #ffffff;
        /* Gray 5 */

        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 8px;
        width: 53px;
        position: relative;
        bottom: 50px;
        img {
          width: 53px;
          height: 53px;
        }
      }
      .top {
        height: 162px;
        background: #ffffff;
        /* Gray 6 */

        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        border-radius: 10px;
        .other {
          position: relative;
          bottom: 50px;
          margin: 25px 0 0 0;
          width: 100%;
          overflow-x: auto;
        }
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          /* identical to box height, or 140% */

          display: flex;
          align-items: center;

          color: #000000;
        }
        .name {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: center;

          color: #000000;
        }
        .type {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          display: flex;
          align-items: center;

          color: #666666;
          margin-top: 6px;
        }
        .btns {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 30px;
        }
        .top-border {
          height: 0px;
          border: 1px solid #e0e0e0;
        }
        .nav {
          display: flex;
          justify-content: space-between;
          /*margin-right: 60px;*/
          width: 800px;
          .item {
            .name {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              /* identical to box height */

              display: flex;
              align-items: center;

              /* Gray 2 */

              color: #4f4f4f;
            }

            margin: 23px 0 21px 0;
            cursor: pointer;
          }
          .active {
            .name {
              color: #f57f45;
              font-weight: bold;
            }
            .item-line {
              /*width: 142px;*/
              height: 0px;
              border: 1px solid #f57f45;
              position: relative;
              top: 21px;
            }
          }
          .basic-document {
            .name {
              ont-weight: 600;
              font-size: 16px;
              line-height: 20px;
              /* identical to box height */

              display: flex;
              align-items: center;

              /* Gray 5 */

              color: #e0e0e0;
            }
          }
          .basic-factory {
            .name {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              /* identical to box height */

              display: flex;
              align-items: center;

              /* Gray 5 */

              color: #e0e0e0;
            }
          }
        }
      }
      .overview {
        background: #ffffff;
        border-radius: 10px;
        margin-top: 28px;
        .title {
          padding-top: 25px;
        }
        .detail {
          /*margin: 0 53px;*/
          padding-bottom: 43px;
          .item {
            display: flex;
            margin: 30px auto 0 auto;
          }
          .c-item {
            display: flex;
            .label {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              /* identical to box height */

              display: flex;
              align-items: center;

              /* Black */

              color: #222222;
              width: 120px;
            }
            .label2 {
              width: 200px;
            }
            .value {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              display: flex;
              align-items: center;

              color: #666666;
              width: 230px;
            }
          }
        }
      }
      .hightlight {
        background: #ffffff;
        border-radius: 10px;
        margin-top: 28px;
        padding-bottom: 48px;
        .title {
          padding-top: 25px;
          margin-bottom: 20px;
        }
        .high-light-item {
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #444444;
          margin-top: 10px;
          margin-bottom: 10px;
          .content {
          }
        }
      }
      .photo {
        background: #ffffff;
        border-radius: 10px;
        margin-top: 28px;
        height: 275px;
        .title {
          padding-top: 25px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 35px;
          .name {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            /* identical to box height, or 140% */

            display: flex;
            align-items: center;

            color: #000000;
          }
          .icon {
            display: flex;
            justify-content: center;
          }
        }
        .imgshows {
          width: 200px;
          height: 150px;
          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .video {
        background: #ffffff;
        border-radius: 10px;
        margin-top: 28px;
        .title {
          padding-top: 25px;
        }
        .video {
          .content {
            .video-box {
              .video-content {
                width: 250px;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .right {
      width: 388px;
      height: 425px;
      left: 967px;
      top: 262px;
      background: #ffffff;
      border-radius: 10px;
      padding-bottom: 20px;
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */

        display: flex;
        align-items: center;

        color: #000000;
      }
      .right-main {
        display: flex;
        margin-bottom: 24px;
        margin-top: 28px;
        .logo {
          width: 100px;
          height: 100px;
          justify-content: center;
          display: flex;
          margin-right: 28px;
          img {
            border-radius: 50%;
          }
        }
        .info {
          margin-top: 5px;
        }
        .name {
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
          /* identical to box height, or 167% */

          display: flex;
          align-items: center;
          /*justify-content: center;*/
          /* Black */

          color: #222222;
          text-transform: capitalize;
        }
        .job {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;
          /*justify-content: center;*/
          color: #444444;
        }
        .contact {
          width: 122px;
          height: 38px;
          background: #f57f45;
          border-radius: 8px;
          align-items: center;
          text-align: center;

          /* White */
          justify-content: center;
          color: #ffffff;
          display: flex;
          margin: 30px auto 0 auto;
        }
      }
      .border {
        height: 0px;
        border: 1px solid #e0e0e0;
      }
      .quick {
        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #4f4f4f;
          margin: 25px 0 20px 0;
        }
        .actions {
          .item {
            display: flex;
            border: 1px solid #f57f45;
            box-sizing: border-box;
            border-radius: 8px;
            width: 301px;
            height: 42px;
            margin-bottom: 12px;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 18px;
              margin-right: 18px;
            }

            .word {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;

              display: flex;
              align-items: center;
              text-align: center;
              color: #222222;
            }
          }
        }
      }
    }
  }

  .card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    display: flex;
    align-items: center;

    color: #000000;
    margin: 30px 0 0 0;
  }
}
.padding-common {
  padding-left: 10px;
  padding-right: 10px;
}
.not-login {
  margin-top: 27px;
  .pic {
    img {
      width: 100%;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 54px;
    text-align: center;
    font-size: 21px;

    font-weight: 500;
    color: #ef842d;
    .warning {
      position: relative;
      right: 11px;
      top: 7px;
      img {
        width: 15px;
      }
    }
  }
}
</style>
