<template>
  <div style="min-height: 400px">
    <pc v-if="!mobileWidth" />
    <mobile v-if="mobileWidth" />
  </div>
</template>

<script>
// import gold from "./company-gold-info"
// import diamond from "./company-diamond-info"
import mobile from "./company-info-mobile";
// import basic from "./company-basic-info"
import pc from "./company-info-pc";
export default {
  computed: {},
  components: {
    // gold,
    // diamond,
    // basic,
    mobile,
    pc
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      loading: false
      // role: "",
      // company: null
    };
  },
  created() {},
  filters: {}
};
</script>

<style lang="less" scoped></style>
