import { render, staticRenderFns } from "./company-info.vue?vue&type=template&id=2d49a875&scoped=true&"
import script from "./company-info.vue?vue&type=script&lang=js&"
export * from "./company-info.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d49a875",
  null
  
)

export default component.exports