<template>
  <div class="card-content">
    <div class="main">
      <div class="load-data" v-if="loading">
        <div class="pic"><img src="@/assets/loading.gif" /></div>
      </div>
      <div class="list-data" v-else-if="list.length > 0">
        <div
          class="item"
          :key="product.id"
          v-for="product in list"
          @click="viewProduct(product)"
        >
          <div class="top">
            <img :src="product.images[0].url" />
          </div>
          <div class="bottom">
            <div class="logo">
              <div class="back">
                <img :src="product.company.avatar.url" />
              </div>
            </div>
            <div class="title">{{ product.marketing_name }}</div>
            <div class="company">{{ product.company.company_name }}</div>
            <!--            <div class="label">PRODUCT CATEGORY</div>-->
            <div class="value">{{ product.product_type }}</div>
            <!--                <div class="label">ORIGIN</div>-->
            <!--                <div class="value">China</div>-->
            <div class="tag" :style="{ background: product.tags[0].color }">
              {{ product.tags[0].name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page" v-if="list.length > 0">
      <Page
        transfer
        :page-size="limit"
        :page-size-opts="[10, 15, 20]"
        :total="total"
        show-sizer
        show-elevator
        show-total
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryCompanyProduct } = api;
export default {
  computed: {},
  props: ["id", "search"],

  components: {},
  data() {
    return {
      list: [],
      companyProduct: [],
      page: 1,
      limit: 15,
      filter: {},
      loading: true
    };
  },
  created() {
    const id = this.id;
    const searchValue = this.search;
    _queryCompanyProduct({ id: id, limit: 30, s: searchValue })
      .then(response => {
        const { data } = response;
        this.list = data.data;
        this.companyProduct = data.data;
        // console.log(this.companyProduct)
        this.total = data.total;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {},
  methods: {
    viewProduct(item) {
      if (item.redirect_url) {
        window.open(item.redirect_url);
      } else if ("Basic" === item.company.level) {
        this.$router.push(`/company/` + item.company.id);
      } else {
        this.$router.push(`/products/` + item.id);
      }
    },
    pageChange(page) {
      this.page = page;
      this.queryProductList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryProductList();
    },
    handleSearch() {
      this.page = 1;
      this.queryProductList();
    },
    queryProductList() {
      _queryCompanyProduct({
        id: this.id,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .list-data {
    .item {
      width: 345px;
      /*height: 460px;*/
      margin-right: 15px;
      .top {
        width: 345px;
        height: 222px;
        img {
          width: 345px;
          height: 222px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
          width: 300px;
        }
        .company {
          top: -90px;
          width: 320px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .list-data {
    .item {
      width: 394px;
      /*height: 470px;*/
      margin-right: 15px;
      .top {
        width: 394px;
        height: 238px;
        img {
          width: 394px;
          height: 238px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
          width: 350px;
        }
        .company {
          top: -90px;
          width: 360px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .list-data {
    .item {
      width: 375px;
      /*height: 470px;*/
      margin-right: 15px;
      .top {
        width: 375px;
        height: 210px;
        img {
          width: 375px;
          height: 210px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
          width: 320px;
        }
        .company {
          top: -90px;
          width: 360px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
}
.card-content {
  background: #ffffff;
  margin: 0 auto 24px auto;
  /*padding-bottom: 20px;*/
  .main {
    /*padding-top: 34px;*/
    .content {
      .item {
        display: flex;
        div {
          text-align: center;
          width: 50%;
          font-size: 16px;

          font-weight: 600;
          color: #333333;
          line-height: 18px;
          height: 47px;
          border-bottom: 0.5px solid #e0e0e0;
          padding-top: 13px;
        }
      }
      .no-data {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
  .page {
    text-align: CENTER;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    background: #ffffff;
    border-radius: 8px;
  }
}
.list-data {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  /*margin-left:10px;*/
  background: #f2f2f2;
  padding-bottom: 20px;
  .item {
    background: #ffffff;
    border-radius: 5px;
    margin-top: 24px;
    cursor: pointer;
    border-radius: 10px;
    .top {
      border-radius: 5px;
    }
    .bottom {
      .logo {
        /*background: #FFFFFF;*/
        position: relative;
        left: 10px;
        .back {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          text-align: center;
          vertical-align: middle;
          background: #ffffff;
          padding-top: 10px;
          img {
            width: 70px;
            height: 70px;
            border-radius: 7px;
          }
        }
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #252729;
        position: relative;
        left: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .company {
        font-size: 16px;
        font-weight: 400;
        color: #808080;
        position: relative;
        left: 22px;
      }
      .label {
        font-size: 12px;
        font-weight: 500;
        color: #808080;
        position: relative;
        left: 22px;
      }
      .value {
        /*width: 44px;*/
        height: 9px;
        font-size: 16px;
        font-weight: 400;
        color: #808080;
        position: relative;
        left: 22px;
      }
      .tag {
        width: 122px;
        height: 32px;
        background: #ef842d;
        border-radius: 5px;
        position: relative;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.load-data {
  margin-top: 30px;
  text-align: center;
  .pic {
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
