<template>
  <div class="card-content">
    <div class="main">
      <div class="content" v-if="list.length > 0">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="main-content">
            <div class="logo">
              <img :src="item.avatar.url" alt="" />
            </div>
            <div class="info">
              <div class="name">{{ item.last_name }}</div>
              <div class="job">{{ item.job_title }}</div>
              <div
                class="contact search-button"
                @click="message(item.company_id, item.id)"
              >
                Message
              </div>
            </div>
          </div>
          <div class="main-contact" v-if="item.is_administrator">
            Main Contact
          </div>
        </div>
      </div>
    </div>
    <Modal class="seller-c" title="Info" @on-cancel="cancel" v-model="showInfo">
      <div class="content">
        Seller does not have the permission.
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const { _queryCompanyUser } = api;
export default {
  computed: {},
  props: ["id", "role"],

  components: {},
  data() {
    return {
      list: [],
      page: 1,
      limit: 999,
      filter: {},
      total: 0,
      loading: true,
      showInfo: false
    };
  },
  created() {
    const id = this.id;
    _queryCompanyUser({
      id: id,
      page: this.page,
      limit: this.limit,
      ...this.filter
    })
      .then(response => {
        const { data } = response;
        this.list = data.data;
        this.total = data.total;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {},
  methods: {
    message(companyId, userId) {
      if ("exhibitor" === this.role) {
        this.showInfo = true;
        return;
      }
      this.$router.push("/chat?company=" + companyId + "&user=" + userId);
    },
    pageChange(page) {
      this.page = page;
      this.queryMemberList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMemberList();
    },
    handleSearch() {
      this.page = 1;
      this.queryMemberList();
    },
    queryMemberList() {
      _queryCompanyUser({
        id: this.id,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .content {
    .item {
      width: 520px;
    }
    .item:nth-child(odd) {
      margin-right: 35px;
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .content {
    .item {
      width: 400px;
      .main-contact {
        position: relative;
        left: 17px;
      }
    }
    .item:nth-child(odd) {
      margin-right: 20px;
    }
  }
  .main-contact {
    left: 17px !important;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .content {
    .item {
      width: 380px;
      .main-contact {
        position: relative;
        left: 0px;
      }
    }
    .item:nth-child(odd) {
      margin-right: 15px;
    }
  }
  .main-contact {
    left: 0px !important;
  }
}
@media (max-width: 1200px) {
}
.card-content {
  border-radius: 10px;
  margin: 28px auto;
  .main {
    .title {
      display: flex;
      height: 40px;
      .item {
        text-align: center;
        padding-top: 8px;
        font-size: 16px;

        font-weight: 500;
        color: #828282;
        width: 50%;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .item {
        display: flex;
        margin-bottom: 24px;
        /*margin-top: 28px;*/
        height: 166px;
        background: #ffffff;
        border-radius: 8px;
        width: 100%;
        .main-content {
          display: flex;
          margin-top: 16px;
          margin-left: 35px;
        }
        .logo {
          width: 80px;
          height: 80px;
          justify-content: center;
          display: flex;
          margin-right: 28px;
          img {
            border-radius: 50%;
          }
        }
        .info {
          margin-top: 5px;
        }
        .name {
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
          /* identical to box height, or 167% */

          display: flex;
          align-items: center;
          /*justify-content: center;*/
          /* Black */

          color: #222222;
          text-transform: capitalize;
        }
        .job {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;
          /*justify-content: center;*/
          color: #444444;
        }
        .contact {
          width: 122px;
          height: 38px;
          background: #f57f45;
          border-radius: 8px;
          align-items: center;
          text-align: center;

          /* White */
          justify-content: center;
          color: #ffffff;
          display: flex;
          margin: 30px auto 0 auto;
        }
      }
      .no-data {
        text-align: center;
        margin-top: 30px;
      }
    }
    .main-contact {
      width: 118px;
      height: 26px;
      background: #f99d20;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;

      color: #ffffff;
      justify-content: center;
      margin-top: 16px;
      /*position: relative;*/
      /*left: 138px;*/
    }
  }
  .page {
    text-align: CENTER;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
.load-data {
  margin-top: 30px;
  text-align: center;
  .pic {
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
